import React from "react";
import "../../style/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../style/style.css";
import SearchIcon from '@mui/icons-material/Search';
const SearchNews = () => {
  return (
    <>
<section class="breaking-news-section py-5 section-paddingfix">
    <div className="container">
        <div className="row">
            <div className="col-sm-12">
            <h2 class="latestbracking-news">Search Results <SearchIcon style={{fontSize: '28px'}}/></h2>

            </div>
        </div>
        <div className="row" style={{background: 'rgba(0, 0, 102, 0.09)'}}>
        <div class="col-md-12 p-0" style={{borderRight: '1px solid rgb(255, 255, 255)'}}><div class="leftarea-inner d-flex"><div class="content ps-2"><p class="m-0">Kaif's Intriguing "Keep Dhoni Indoors" Remark On Cummins' On-Field Act</p></div> </div></div>
        </div>
        <div className="row" style={{background: 'rgba(0, 0, 102, 0.09)'}}>
        <div class="col-md-12 p-0" style={{borderRight: '1px solid rgb(255, 255, 255)'}}><div class="leftarea-inner d-flex"><div class="content ps-2"><p class="m-0">Kaif's Intriguing "Keep Dhoni Indoors" Remark On Cummins' On-Field Act</p></div> </div></div>
        </div>
        <div className="row" style={{background: 'rgba(0, 0, 102, 0.09)'}}>
        <div class="col-md-12 p-0" style={{borderRight: '1px solid rgb(255, 255, 255)'}}><div class="leftarea-inner d-flex"><div class="content ps-2"><p class="m-0">Kaif's Intriguing "Keep Dhoni Indoors" Remark On Cummins' On-Field Act</p></div> </div></div>
        </div>
        <div className="row" style={{background: 'rgba(0, 0, 102, 0.09)'}}>
        <div class="col-md-12 p-0" style={{borderRight: '1px solid rgb(255, 255, 255)'}}><div class="leftarea-inner d-flex"><div class="content ps-2"><p class="m-0">Kaif's Intriguing "Keep Dhoni Indoors" Remark On Cummins' On-Field Act</p></div> </div></div>
        </div>
    </div>
</section>
    </>
  );
};

export default SearchNews;
