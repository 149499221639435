import React from 'react'
import { Link } from 'react-router-dom'
// import breakingNews from '../../assets/images/breaking-news.webp'
// import { useState } from 'react'
// import axios from 'axios'
import { useLanguage } from '../../utils/LanguageContext'
// import { useEffect } from 'react'
const Categories = () => {
    const { newsWithCategory } = useLanguage();
    // console.log("News>>>>", newsWithCategory)
    // const [categoryNews, setCategoryNews] = useState([]);

    // const handleCategoryHover = async (categoryId, lang) => {
    //     try {
    //         const response = await axios.get(`${process.env.REACT_APP_API_URL}/news/getnewsByCategory/${lang}/${categoryId}`);
    //         setCategoryNews(response.data); // Assuming response.data contains the news for the specified category
    //         // console.log('News for category', categoryId, ':', response.data);
    //     } catch (error) {
    //         console.error('Error fetching news by category:', error);
    //     }
    // };
    // useEffect(() => {
    //     // Fetch news for the initial language
    //     categories.forEach(category => {
    //         handleCategoryHover(category._id);
    //     });
    // }, [language, categories]);
    const handleLinkClick = () => {
        window.scrollTo(0, 0);
    };
    return (
        <div>
            <div className="categories-section">
                <div className="container-fluid px-0">
                    <div className='categories_overflow_menu'>
                        <ul className='cat_menu_list'>
                            {newsWithCategory.map((category, index) => (
                                index < 6 && (
                                    <li ><Link className='menu_item' to={`/category/${category.handle}`} >{category.category}</Link>
                                        <div className='sub_menu'>
                                            <ul className='sub_menu_items'>
                                                {category.newsItems.map((newsItem, index) => (
                                                    index < 4 && (
                                                        <li key={newsItem._id}>
                                                            <Link className='sub_menu_item' to={`/news/${newsItem.handle}`} onClick={handleLinkClick}>
                                                                <div className='menu_item_img'>
                                                                    <img src={newsItem.img} alt="" />
                                                                </div>
                                                                <p>{newsItem.title.substring(0, 60)}...</p>
                                                            </Link>
                                                        </li>
                                                    )
                                                ))}
                                            </ul>
                                        </div>
                                    </li>
                                )
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Categories
